<script setup lang="ts">
import type { Game } from "@/types";

withDefaults(
	defineProps<{
		games: Game[];
		show?: "grid" | "slider";
		hideFavoriteBtn?: boolean;
	}>(),
	{ show: "grid" }
);
const { t } = useT();
const { open } = useAppModals();
const { data: appInitData } = useAppInitData();
const { handleOpenGame } = useOpenGame(open);
const { handleToggleToFavoriteClick } = useAddGameToFavorite({ t, toastTheme: "dark", toastPosition: "bottom-center" });

const handlePlayClick = (game: Game) => {
	if (!game.isOnlyForApp) {
		handleOpenGame(game.slug);
	}
};
</script>

<template>
	<template v-if="show === 'grid'">
		<div class="grid-cards">
			<MGame
				v-for="game in games"
				:key="game.id ?? 0"
				class="game"
				:game="game"
				:skeleton="'skeleton' in game"
				:last-games="appInitData?.lastGames"
				@toggle-favorite="handleToggleToFavoriteClick(game)"
				@play="handlePlayClick(game)"
			/>
		</div>
	</template>

	<template v-if="show === 'slider'">
		<OGamesSlider>
			<MGame
				v-for="game in games"
				:key="game.id"
				:game="game"
				class="keen-slider__slide"
				@toggle-favorite="handleToggleToFavoriteClick(game)"
				@play="handlePlayClick(game)"
			/>
		</OGamesSlider>
	</template>
</template>

<style lang="scss" scoped>
.keen-slider__slide {
	min-width: 160px;
	max-width: 160px;
}
.grid-cards {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 12px;

	@include media-breakpoint-down(lg) {
		gap: 8px;
	}

	@media (max-width: 530px) {
		justify-content: center;
	}

	.game {
		width: 179px;
		height: 200px;

		@include media-breakpoint-down(md) {
			width: 108px;
			height: 120px;
		}
	}
}
</style>

<style lang="scss">
.icon-favorite-add {
	background-image: url(/assets/20/favorite-add.svg);
}

.icon-favorite-remove {
	background-image: url(/assets/20/favorite-remove.svg);
}

.Toastify__toast-container--bottom-center {
	@include media-breakpoint-up(lg) {
		left: auto;
		right: 16px;
		transform: translateX(0%);
	}
}
</style>
